import React from "react"
import styled from "styled-components"
import { mq } from "../utils/presets" // import LogoWeiss from "../images/wildgerecht-logo-weiss.svg"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Wrapper = styled.div`
  margin-top: 5.4rem;
  height: 215px;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
  ${mq.tablet} {
    height: 60vh;
  }
`


const TopImage = ({ featuredImage }) => {
  
  const headerImage = {
    image: getImage(featuredImage?.node?.localFile),
    alt: featuredImage?.altText || "",
  }


  return (
    <Wrapper>
      {!!headerImage && (
        <GatsbyImage
          image={headerImage.image}
          alt={headerImage.alt}
        />
      )}
    </Wrapper>
  )
}

export default TopImage
