import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import { mq, colors } from "../utils/presets"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/pro-light-svg-icons"
import { faPhone } from "@fortawesome/pro-light-svg-icons"
import { faMapMarkerAlt } from "@fortawesome/pro-light-svg-icons"
import { faClock } from "@fortawesome/pro-light-svg-icons"
import { faFax } from "@fortawesome/pro-light-svg-icons"
import TopImage from "../components/TopImage"
import { StaticImage } from "gatsby-plugin-image"

const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  ${mq.tablet} {
    max-width: 1080px;
    margin: 0 auto;
    margin-bottom: 3rem;
  }
`

const Textwrapper = styled.div`
  flex: 1 0 auto;
  width: 100%;
  h1 {
    padding: 1rem 1rem 0;
  }
  ${mq.tablet} {
    padding: 1rem 1rem 0;
    width: 35%;
    margin-right: 1rem;
    h1 {
      padding: 0;
      text-align: left;
      border-bottom: none;
      margin-bottom: 0;
      margin-right: 1rem;
    }
  }
  ${mq.tablet} {
    margin-right: 0;
    padding-right: 0;
  }
`

const GoogleMapsWrapper = styled.div`
  flex: 0 1 auto;
  width: 100%;
  line-height: 0;
  margin-top: 3rem;
  height: 400px;
  position: relative;
  ${mq.tablet} {
    height: initial;
    width: 59%;
    margin-left: 1rem;
    margin-top: 5.2rem;
  }
  picture {
    opacity: 0.2;
  }
  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
  }
  .ladenbutton {
    display: inline;
    background: #4a4a4a;
    color: white;
    padding: 0.4rem 1.3rem;
    border: none;
    border-radius: 12px;
    text-transform: uppercase;
    font-size: 1rem;
    text-decoration: none;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.25);
    &:focus,
    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }
  .maptext {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    color: black;
    text-align: center;
    background: rgba(0, 0, 0, 0.2);
    line-height: 1.6;
    .centertext {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      margin-top: -2rem;
      max-width: 350px;
      margin: 0 auto;
    }
  }
`

const Contactdata = styled.div`
  margin-bottom: 0;
  a {
    text-decoration: none;
    &:hover,
    &:focus {
      div {
        background: #ececec;
      }
    }
  }
  .firstlink {
    border-top: none;
  }
  .zeiten {
    p {
      &:first-child {
        margin-bottom: 0.3rem;
      }
    }
  }
`

const Contactfield = styled.div`
  background: ${colors.lightgrey};
  padding: 1rem;
  margin: 1rem 1rem 0 0;
  width: 100%;
  display: flex;
  flex-flow: row;
  .faicon {
    margin-right: 1rem;
    flex: 0 1 auto;
    align-self: center;
  }
  p {
    margin-bottom: 0;
  }
  ${mq.tablet} {
    .faicon {
      margin-right: 2rem;
    }
  }
`

const ContactPageTemplate = ({ data }) => {
  const page = data.page
  const featuredImage = page?.featuredImage

  const seo = page?.seo
  const seoTitle = page?.seo?.opengraphTitle || page.title || ``
  const metaDesc = seo.metaDesc || ``
  const seoImage = page?.featuredImage?.node.localFile.childImageSharp.resize

  const iframe = `<iframe
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2567.269762314306!2d11.584362115898053!3d49.95004193117505!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a1a2dc1396a9a9%3A0xd9836bfc71013b96!2sRSP+Architektur+%2B+Stadtplanung+GmbH!5e0!3m2!1sde!2sde!4v1558622986348!5m2!1sde!2sde"
  frameBorder="0"
  allowFullScreen
  title="RSP Architektur Google Maps"
  width="100%"
  height="492px"
  />`

  function handleClick(e) {
    e.preventDefault()
    // Create Element.remove() function if not exist
    if (!("remove" in Element.prototype)) {
      Element.prototype.remove = function () {
        if (this.parentNode) {
          this.parentNode.removeChild(this)
        }
      }
    }
    const maptext = document.getElementById("maptext")
    maptext.remove()
    const div = document.getElementById("mapsframe")
    div.firstElementChild.remove()
    const gmapiframe = iframe
    div.innerHTML += gmapiframe
  }

  return (
    <Layout isFrontPage={page.isFrontPage}>
      <Seo
        title={page.title}
        seoTitle={seoTitle}
        description={metaDesc}
        image={seoImage}
        uri={page.uri}
      />{" "}
      {!!featuredImage && <TopImage featuredImage={featuredImage} />}
      <Wrapper>
        <Textwrapper>
          <h1>{page.title}</h1>
          <Contactdata>
            <a
              href="https://www.google.com/maps/dir/?api=1&destination=RSP%20Architektur%20+%20Stadtplanung%20Gmbh"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Contactfield className="firstlink">
                <FontAwesomeIcon
                  className="faicon"
                  icon={faMapMarkerAlt}
                  size="lg"
                />{" "}
                <div
                  dangerouslySetInnerHTML={{
                    __html: page.kontaktdaten.anschrift,
                  }}
                />
              </Contactfield>
            </a>
            <a href={"tel:" + page.kontaktdaten.telefonnummer}>
              <Contactfield>
                <FontAwesomeIcon size="lg" className="faicon" icon={faPhone} />
                Tel: {page.kontaktdaten.telefonnummer}
              </Contactfield>
            </a>

            <a href={"tel:" + page.kontaktdaten.fax}>
              <Contactfield>
                <FontAwesomeIcon size="lg" className="faicon" icon={faFax} />
                Fax: {page.kontaktdaten.fax}
              </Contactfield>
            </a>
            <a href={"mailto:" + page.kontaktdaten.eMail}>
              <Contactfield>
                <FontAwesomeIcon
                  size="lg"
                  className="faicon"
                  icon={faEnvelope}
                />
                E-Mail: {page.kontaktdaten.eMail}
              </Contactfield>
            </a>
            <Contactfield className="zeiten">
              <FontAwesomeIcon size="lg" className="faicon " icon={faClock} />
              <div
                dangerouslySetInnerHTML={{
                  __html: page.kontaktdaten.offnungszeiten,
                }}
              />
            </Contactfield>
          </Contactdata>

          {/* <div dangerouslySetInnerHTML={{ __html: page.content }} /> */}
        </Textwrapper>
        <GoogleMapsWrapper id="mapsframe">
          <StaticImage
            className="mappreview"
            src="../../static/mappreview.jpg"
            alt="Google Maps Vorschaubild des RSP Architektur + Stadtplanung Standorts"
            height={750}
            objectFit="cover"
            loading="lazy"
          />
          <div id="maptext" className="maptext">
            <div className="centertext">
              <h2>Standort</h2>
              <p>
                Mit dem Laden der Karte akzeptieren Sie die{" "}
                <a
                  href="https://policies.google.com/privacy"
                  rel="nofollow noreferrer noopener"
                  target="_blank"
                >
                  Daten­schutzerk­lärung
                </a>{" "}
                von Google.
              </p>
              <button className="button ladenbutton" onClick={handleClick}>
                Karte laden
              </button>
            </div>
          </div>
        </GoogleMapsWrapper>
      </Wrapper>
    </Layout>
  )
}
export default ContactPageTemplate

export const query = graphql`
  query ($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      isFrontPage
      uri
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        opengraphTitle
        opengraphDescription
        schema {
          articleType
          pageType
          raw
        }
      }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              resize(width: 1200, height: 627) {
                src
                tracedSVG
                width
                height
                aspectRatio
                originalName
              }
              gatsbyImageData(
                width: 2000
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
      kontaktdaten {
        anschrift
        eMail
        fax
        offnungszeiten
        telefonnummer
      }
    }
  }
`
